const PERSONAL = 'personal';
const MEAL = 'meal';
const DINING_MEAL_USER_EVENT = 'dining';
const SNACK = 'snack';
const OTHER = 'other';
const ALL = 'all';

const ACTIVITY_TYPES = {
  [PERSONAL]: 'Personal',
  [MEAL]: 'Meal',
  [DINING_MEAL_USER_EVENT]: 'Meal',
  [SNACK]: 'Snack',
  [OTHER]: 'Other',
};

const DEFAULT_ACTIVITY_TYPES_MAPPINGS = [
  { key: PERSONAL, name: ACTIVITY_TYPES[PERSONAL] },
  { key: MEAL, name: ACTIVITY_TYPES[MEAL] },
  { key: DINING_MEAL_USER_EVENT, name: ACTIVITY_TYPES[DINING_MEAL_USER_EVENT] },
  { key: SNACK, name: ACTIVITY_TYPES[SNACK] },
  { key: OTHER, name: ACTIVITY_TYPES[OTHER] },
];

const MEAL_TYPES = [SNACK, MEAL];

module.exports = {
  PERSONAL,
  ALL,
  MEAL,
  DINING_MEAL_USER_EVENT,
  SNACK,
  OTHER,
  ACTIVITY_TYPES,
  MEAL_TYPES,
  MEAL_AND_PERSONAL: [...MEAL_TYPES, PERSONAL],
  DEFAULT_ACTIVITY_TYPES_MAPPINGS,
};
