const moment = require('moment');
const { orderBy, first, floor } = require('lodash');
const { DATE_FORMATS } = require('../constants/time');
const { SPECIAL_TIME_TYPES } = require('../constants/special.time.types');
const parseTimeString = require('../lib/magic.time');

const combineTimeStringAndDate = (timeString, date) => {
  let time;
  try {
    time = moment(parseTimeString(timeString));
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
  if (!time) {
    time = moment(new Date(timeString));
  }
  const datemoment = moment.utc(date);
  time
    .year(datemoment.year())
    .month(datemoment.month())
    .date(datemoment.date())
    .millisecond(0);
  return time;
};

module.exports = {
  RECUR_DATE: DATE_FORMATS.MM_DD_YYYY,
  formatDateToString(date, format = DATE_FORMATS.MM_DD_YYYY) {
    if (!date || !moment(date).isValid()) {
      return '';
    }
    return moment(date)
      .format(format)
      .toString();
  },
  validateStartAndEndTimes: (start, end) => {
    if (!start || !end) {
      return false;
    }
    const date = new Date();
    const momentStart = combineTimeStringAndDate(start, date);
    const momentEnd = combineTimeStringAndDate(end, date);
    return momentStart.isBefore(momentEnd);
  },
  validateNoTimeOverlap: (optionA, optionB) => {
    const date = new Date();
    const momentAStart = combineTimeStringAndDate(optionA.startTime, date);
    const momentAEnd = combineTimeStringAndDate(optionA.endTime, date);
    const momentBStart = combineTimeStringAndDate(optionB.startTime, date);
    const momentBEnd = combineTimeStringAndDate(optionB.endTime, date);
    return (
      momentAStart.isSameOrAfter(momentBEnd) ||
      momentAEnd.isSameOrBefore(momentBStart)
    );
  },
  getSpecialSortKeyValue: model => {
    if (model.special === SPECIAL_TIME_TYPES.allDay) {
      return 1;
    }
    if (model.special === SPECIAL_TIME_TYPES.morning) {
      return 2;
    }
    return 3;
  },
  sortByTimeField: (field, secondarySortField = null) => (a, b) => {
    const value = field.charAt(0) === '-' ? -1 : 1;
    const key = field.replace('-', '');
    const A = a[key];
    const B = b[key];
    if (A && !B) {
      return -value;
    }
    if (B && !A) {
      return value;
    }
    const aMoment = moment(new Date(A));
    const bMoment = moment(new Date(B));
    if (aMoment.isSame(bMoment) && secondarySortField) {
      return `${a[secondarySortField].attr}`.localeCompare(
        `${b[secondarySortField].attr}`,
      );
    }
    return aMoment.isBefore(bMoment) ? -value : value;
  },
  sortByFields: fields => {
    const mapSortFields = model =>
      fields.reduce((result, { keys, isMoment }, index) => {
        const sortKey = `sortKey-${index}`;
        const value = first(
          keys.map(key => model[key]).filter(valueForKey => !!valueForKey),
        );
        return {
          ...result,
          [sortKey]: value && isMoment ? value.valueOf() : value,
        };
      }, {});

    return models =>
      orderBy(
        models.map(model => ({
          ...model,
          ...mapSortFields(model),
        })),
        fields.map((field, index) => `sortKey-${index}`),
        fields.map(({ direction }) => direction || 'asc'),
      );
  },
  combineTimeStringAndDate,
  // only use normalizeDateTimestamp when submitting date values,
  // not when initializing values or updating client side
  normalizeDateTimestamp: date =>
    moment
      .utc(date)
      .hour(12)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString(),
  roundDateToNearest30MinuteInterval(date) {
    const start = moment(date);
    const remainder = 30 - (start.minute() % 30);
    return start.add(remainder, 'minutes');
  },
  nearestTimeInterval({ date = new Date(), interval = 30 } = {}) {
    const roundedMinutes =
      Math.round(moment(date).minute() / interval) * interval;
    return moment(date)
      .minute(roundedMinutes)
      .second(0)
      .millisecond(0);
  },
  handleNewEndTime: ({ endTime, startTime }, defaultDuration = 1) => {
    const startMoment = moment(parseTimeString(startTime))
      .second(0)
      .millisecond(0);
    const endMoment = moment(parseTimeString(endTime))
      .second(0)
      .millisecond(0);
    if (startMoment.isSameOrAfter(endMoment)) {
      return endMoment.subtract(defaultDuration, 'hours');
    }
    return startMoment;
  },
  handleNewStartTime: ({ endTime, startTime }, defaultDuration = 1) => {
    const endMoment = moment(parseTimeString(endTime))
      .second(0)
      .millisecond(0);
    const startMoment = moment(parseTimeString(startTime))
      .second(0)
      .millisecond(0);
    if (endMoment.isSameOrBefore(startMoment)) {
      return startMoment.add(defaultDuration, 'hours');
    }
    return endMoment;
  },
  formatCreatedTimestamp: timestamp => {
    const date = moment(timestamp);
    if (date.isSame(moment(), 'day')) {
      return date.fromNow();
    }
    return date.format('hh:mma [on] MMM Do, YYYY');
  },
  formatDateForDetails(date) {
    if (!date) {
      return 'No info';
    }
    if (moment(date).isSame(moment(), 'day')) {
      return 'Today';
    }
    return `${moment(date).format('MMMM Do, YYYY')} (${moment().to(
      moment(date),
    )})`;
  },
  getCronString(dateMoment) {
    const minute = dateMoment.minute();
    const hour = dateMoment.hour();
    const date = dateMoment.date();
    const month = dateMoment.month() + 1;
    const dayOfWeek = dateMoment.day();
    return `${minute} ${hour} ${date} ${month} ${dayOfWeek}`;
  },
  formatSecondsToReadableString(seconds) {
    const minutes = floor(seconds / 60);
    if (minutes) {
      return `${minutes}m ${seconds - 60 * minutes}s`;
    }
    return `${seconds}s`;
  },
};
