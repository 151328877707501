import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FlexContainer, Icon, IconType } from 'web/components/primitives';
import { X } from 'assets/icons';

import styles from './styles.scss';

export default function HeaderBar({
  title,
  onBack,
  onClose,
  children,
  className,
  ...rest
}) {
  return (
    <FlexContainer
      alignItems="center"
      className={classNames(className, styles.header)}
    >
      <FlexContainer flex="1" alignItems="center">
        {!!onBack && (
          <Icon
            type={IconType.arrowLeft}
            onClick={onBack}
            className={styles.icon}
            data-qa-back
          />
        )}
        {!!title && <h1 className={styles.title}>{title}</h1>}
        {!!children && (
          <FlexContainer
            alignItems="center"
            justifyContent="flexEnd"
            flex="1"
            {...rest}
          >
            {children}
          </FlexContainer>
        )}
      </FlexContainer>
      {!!onClose && (
        <X onClick={onClose} className={styles.icon} data-qa-close />
      )}
    </FlexContainer>
  );
}

HeaderBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onBack: PropTypes.func,
};

HeaderBar.defaultProps = {
  className: undefined,
  children: undefined,
  onBack: undefined,
  title: undefined,
};
