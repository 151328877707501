import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DropdownSelect from 'web/components/primitives/dropdown.select';
import PasswordInput from 'web/components/primitives/password.input';
import ButtonInput from 'web/components/primitives/button.input';
import Checkbox from 'stories/checkbox';
import Icon from 'stories/icon';
import Radio from 'stories/radio';

import styles from './styles.scss';

export default function Input({
  rows,
  type,
  isError,
  isFullWidth,
  iconName,
  className,
  forwardedRef,
  wrapperClassName,
  value,
  style,
  autoComplete,
  ...rest
}) {
  if (type === 'dropdown') {
    return (
      <DropdownSelect
        value={value}
        className={className}
        styles={{ container: provided => ({ ...provided, ...style }) }}
        {...rest}
      />
    );
  }
  if (type === 'button') {
    return (
      <ButtonInput
        value={value}
        className={className}
        style={style}
        isError={isError}
        {...rest}
      />
    );
  }
  if (type === 'textarea') {
    return (
      <textarea
        ref={forwardedRef}
        style={style}
        {...rest}
        className={classNames(styles.input, className, {
          [styles.isError]: isError,
        })}
        value={value}
        rows={rows}
      />
    );
  }
  if (type === 'checkbox') {
    return (
      <Checkbox style={style} value={value} className={className} {...rest} />
    );
  }
  if (type === 'radio') {
    return (
      <Radio style={style} value={value} className={className} {...rest} />
    );
  }
  if (type === 'password') {
    return (
      <PasswordInput
        style={style}
        value={value}
        autoComplete={autoComplete ? 'on' : 'off'}
        className={classNames(styles.input, className, {
          [styles.isError]: isError,
        })}
        wrapperClassName={classNames(styles.inlineBlock, wrapperClassName, {
          [style.fullWidth]: isFullWidth,
        })}
        {...rest}
      />
    );
  }
  const input = (
    <input
      type={type}
      value={value}
      style={style}
      autoComplete={autoComplete ? 'on' : 'off'}
      {...rest}
      ref={forwardedRef}
      className={classNames(styles.input, className, {
        [styles.isError]: isError,
        [styles.iconInput]: !!iconName,
      })}
    />
  );
  if (iconName) {
    return (
      <div
        className={classNames(styles.inlineBlock, wrapperClassName, {
          [style.fullWidth]: isFullWidth,
        })}
      >
        <Icon size={18} type={iconName} className={styles.icon} />
        {input}
      </div>
    );
  }
  return input;
}

Input.propTypes = {
  isFullWidth: PropTypes.bool,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  iconName: PropTypes.string,
  isError: PropTypes.bool,
  style: PropTypes.shape(),
  autoComplete: PropTypes.bool,
};

Input.defaultProps = {
  isFullWidth: false,
  className: undefined,
  wrapperClassName: undefined,
  onChange: () => {},
  placeholder: undefined,
  name: 'lifeloop',
  rows: '3',
  type: 'text',
  iconName: '',
  isError: false,
  style: {},
  autoComplete: false,
};
