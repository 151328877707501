import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button, { ButtonType } from 'stories/button';
import Icon, { IconType } from 'stories/icon';

import Modal from '../modal';
import RowGroup from '../row.group';
import Text from '../text';
import FlexContainer from '../flex-container';
import HeaderBar from '../header.bar';
import FooterBar from '../footer.bar';

import styles from './styles.scss';

export default function ActionModal({
  children,
  title,
  buttons,
  leftButtons,
  header,
  onCancel,
  onCancelText,
  padded,
  includeCancel,
  includeClose,
  infoText,
  warningText,
  height,
  ...rest
}) {
  let rightButtons = buttons;
  if (includeCancel) {
    rightButtons = [
      <Button
        key="cancel"
        type={ButtonType.caution}
        onClick={() => onCancel()}
        data-action-cancel
      >
        {onCancelText}
      </Button>,
      ...rightButtons,
    ];
  }

  let closeIcon;
  if (includeClose) {
    closeIcon = (
      <Icon
        size={50}
        type={IconType.x}
        className={styles.icon}
        onClick={() => onCancel()}
        data-action-close-modal
      />
    );
  }

  let leftButtonGroup = null;
  if (leftButtons.length) {
    leftButtonGroup = <RowGroup>{leftButtons}</RowGroup>;
  }

  let rightButtonGroup = null;
  if (rightButtons.length) {
    rightButtonGroup = (
      <RowGroup justifyContent="flexEnd">{rightButtons}</RowGroup>
    );
  }

  let actionText = null;
  if (warningText || infoText) {
    actionText = (
      <Text
        className={styles.actionText}
        isError={!!warningText}
        bold={!!warningText}
        secondary
        size={16}
        mr="1rem"
      >
        {warningText || infoText}
      </Text>
    );
  }

  return (
    <Modal
      {...rest}
      height={height}
      contentLabel={title}
      onRequestClose={() => onCancel()}
    >
      <div
        onClick={e => e.stopPropagation()}
        onMouseUp={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        className={classNames(styles.contentContainer, {
          [styles.fullHeight]: height,
        })}
      >
        <HeaderBar title={title} className={styles.header}>
          {header}
          {closeIcon}
        </HeaderBar>
        <div
          className={classNames({
            [styles.content]: height,
            [styles.paddedContent]: padded,
          })}
        >
          {children}
        </div>
        <FooterBar
          justifyContent={leftButtonGroup ? 'spaceBetween' : 'flexEnd'}
          className={styles.footer}
        >
          {leftButtonGroup}
          <FlexContainer alignItems="center">
            {actionText}
            {rightButtonGroup}
          </FlexContainer>
        </FooterBar>
      </div>
    </Modal>
  );
}

ActionModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  header: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.node),
  leftButtons: PropTypes.arrayOf(PropTypes.node),
  onCancel: PropTypes.func,
  onCancelText: PropTypes.string,
  padded: PropTypes.bool,
  includeCancel: PropTypes.bool,
  includeClose: PropTypes.bool,
  infoText: PropTypes.string,
  warningText: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ActionModal.defaultProps = {
  children: null,
  title: 'Title',
  header: null,
  buttons: [],
  leftButtons: [],
  onCancel: () => undefined,
  onCancelText: 'Cancel',
  padded: true,
  includeCancel: true,
  includeClose: true,
  infoText: null,
  warningText: null,
  height: null,
};
