import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  VIEW_PERIODS,
  validDateForAllToView,
} from 'shared/utils/calendar.util';
import { FlexContainer, Text } from 'web/components/primitives';
import { Lock } from 'assets/icons';
import { sortByAllDayThenTime } from 'shared/utils/activity.util';
import CalendarHolidayDay from './calendar.holiday.day';

import styles from './styles.scss';

export default function CalendarDay({
  viewPeriod,
  selectedDate,
  isFetching,
  day,
  highlightSelectedDate,
  showRightBorder,
  showAllInMonthGrid,
  isInViewPeriod,
  events,
  holidays,
  highlightedEvents,
  onClickDay,
  hideFutureDates,
  activitiesPerDay,
  releaseDay,
  WeekMonthItem,
}) {
  const date = day.format('L');
  const isMonthView = viewPeriod === VIEW_PERIODS.months.key;
  const isWeekView = viewPeriod === VIEW_PERIODS.weeks.key;
  const dayIsSelected = selectedDate.format('L') === date;
  const dayIsCurrent = moment().format('L') === date;
  const clickDay = isMonthView ? () => onClickDay(day) : () => null;

  let content = null;
  if (isInViewPeriod && !isFetching) {
    const sortedEvents = events.slice().sort(sortByAllDayThenTime);

    content = sortedEvents.map((event, index) => {
      const identifier = event.key || event._id;
      return (
        <WeekMonthItem
          key={identifier}
          holidays={holidays}
          isHighlighted={highlightedEvents.indexOf(identifier) > -1}
          showAllInMonthGrid={showAllInMonthGrid}
          activitiesPerDay={activitiesPerDay}
          totalForDay={events.length}
          viewPeriod={viewPeriod}
          event={event}
          index={index}
        />
      );
    });
    const holidaysWeekMonth = holidays.map(holiday => (
      <CalendarHolidayDay
        activitiesPerDay={activitiesPerDay}
        key={`holiday-weekMonthItem-${holiday._id}`}
        holiday={holiday}
      />
    ));

    content = [...holidaysWeekMonth, ...content];
  } else if (isInViewPeriod) {
    content = ['placeholder1', 'placeholder2', 'placeholder3'].map(key => (
      <FlexContainer
        className={styles.monthEventItem}
        flexDirection="row"
        alignItems="center"
        key={key}
      >
        <div className={styles.circle} />
        <div className={styles.placeholder} />
      </FlexContainer>
    ));
  }
  if (activitiesPerDay === 1) {
    content = (
      <FlexContainer alignItems="center" wrap>
        {content}
      </FlexContainer>
    );
  } else {
    content = <FlexContainer flexDirection="column">{content}</FlexContainer>;
  }
  if (
    viewPeriod === VIEW_PERIODS.weeks.key &&
    hideFutureDates &&
    !validDateForAllToView(day, releaseDay)
  ) {
    content = (
      <FlexContainer
        className={styles.lockedWeekDay}
        alignItems="center"
        justifyContent="center"
      >
        <Lock size="1.3em" />
        <Text meta size="1.2em" ml={4}>
          Locked
        </Text>
      </FlexContainer>
    );
  }
  return (
    <div
      className={classNames(styles.day, {
        [styles.week]: isWeekView,
        [styles.selectedDay]:
          dayIsSelected && isMonthView && highlightSelectedDate,
        [styles.dayRightBorder]: showRightBorder,
        [styles.showAllInMonthGrid]: showAllInMonthGrid,
      })}
      onClick={clickDay}
      key={date}
      data-qa-calendar-day={date}
    >
      {isMonthView && (
        <FlexContainer alignItems="center" justifyContent="spaceBetween">
          <h5
            className={classNames(styles.dayNumber, {
              [styles.dayOutsidePeriod]: !isInViewPeriod,
              [styles.isSelected]: dayIsSelected,
            })}
          >
            {day.format('D')}
          </h5>
          {dayIsCurrent && <span className={styles.today}>Today</span>}
        </FlexContainer>
      )}
      {content}
    </div>
  );
}

CalendarDay.propTypes = {
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  day: PropTypes.instanceOf(moment).isRequired,
  isInViewPeriod: PropTypes.bool.isRequired,
  showAllInMonthGrid: PropTypes.bool.isRequired,
  viewPeriod: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  highlightedEvents: PropTypes.arrayOf(PropTypes.string),
  showRightBorder: PropTypes.bool.isRequired,
  onClickDay: PropTypes.func.isRequired,
  highlightSelectedDate: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  activitiesPerDay: PropTypes.number.isRequired,
  hideFutureDates: PropTypes.bool.isRequired,
  releaseDay: PropTypes.number.isRequired,
  WeekMonthItem: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()])
    .isRequired,
};

CalendarDay.defaultProps = {
  events: [],
  highlightedEvents: [],
  holidays: [],
};
