import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { keys, lowerFirst, values, zipObject } from 'lodash';

import * as FeatherIcons from 'assets/icons';
import { useSpacer } from 'shared/utils/effects';

import styles from './styles.scss';

export const IconType = zipObject(
  keys(FeatherIcons).map(lowerFirst),
  keys(FeatherIcons),
);

export default function Icon({ type, size, className, style, ...rest }) {
  const spacing = useSpacer(rest);
  const FeatherIcon = FeatherIcons[type] || FeatherIcons[IconType.alertCircle];
  return (
    <FeatherIcon
      size={size}
      className={classNames(className, {
        [styles.noMatch]: !FeatherIcons[type],
      })}
      style={{ ...spacing, ...style }}
      {...rest}
    />
  );
}

Icon.propTypes = {
  type: PropTypes.oneOf(values(IconType)).isRequired,
  size: PropTypes.number || PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

Icon.defaultProps = {
  size: 25,
  className: null,
  style: {},
};
