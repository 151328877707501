const ADMIN_PERMISSIONS = {
  canBackdoor: {
    key: 1,
    label: 'Can login into all accounts as support user',
  },
  canManageChangelog: {
    key: 2,
    label: 'Can manage changelog',
  },
  canRunDevJobs: {
    key: 3,
    label: 'Can run developer jobs',
  },
  canRunSupportJobs: {
    key: 4,
    label: 'Can run support jobs',
  },
  canManageLifeloopTeam: {
    key: 5,
    label: 'Can manage LifeLoop team',
  },
  canManageGlobalTemplates: {
    key: 6,
    label: 'Can manage global designer templates',
  },
  canManageMigrations: {
    key: 7,
    label: 'Can manage migrations across accounts',
  },
  canManageLifeLoopExpress: {
    key: 8,
    label: 'Can manage postcards and fulfill orders',
  },
  canSuspendCampus: {
    key: 9,
    label: 'Can suspend campus',
  },
  canToggleLightSwitches: {
    key: 10,
    label: 'Can toggle light switches for new features',
  },
};

const ADMIN_ERRORS = {
  newPasswordNotValid: {
    key: 'newPasswordNotValid',
    message: 'New Password not valid. See Requirements.',
  },
  failedPasswordCheck: {
    key: 'failedPasswordCheck',
    message: 'Password check failed',
  },
  duplicateEmail: {
    key: 'duplicateEmail',
    message: 'Admin user emails must be unique',
  },
  productTeamRequired: {
    key: 'productTeamRequired',
    message: 'Only "Product Team" admin users may perform this action',
  },
};

const ADMIN_USER_STATUS = {
  active: 'active',
  deleted: 'deleted',
};

const ADMIN_USER_TYPE = {
  support: {
    value: 'support',
    label: 'Customer Success',
  },
  dev: {
    value: 'dev',
    label: 'Developer',
  },
  sales: {
    value: 'sales',
    label: 'Sales',
  },
};

module.exports = {
  ADMIN_PERMISSIONS,
  ADMIN_ERRORS,
  ADMIN_USER_STATUS,
  ADMIN_USER_TYPE,
};
