import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';

import { FlexContainer, Icon, IconType } from 'web/components/primitives';

import styles from './styles.scss';

export default function Row({
  row,
  id,
  onRowClick,
  onToggle,
  isSelected,
  columns,
  hoverable,
}) {
  return (
    <tr
      onClick={() => {
        if (onRowClick) {
          onRowClick(row);
        }
      }}
      data-table-row={id}
      className={classNames(row.className, {
        [styles.hoverableRow]: hoverable || onRowClick,
        [styles.clickableRow]: onRowClick,
        [styles.highlighted]: row.isHighlighted,
      })}
      {...(row.attributes || {})}
    >
      {onToggle && (
        <td
          key="selectable"
          className={classNames(
            styles.bodyCell,
            styles.checkbox,
            styles['bodyCell--centered'],
          )}
        >
          <Icon
            size={25}
            type={isSelected ? IconType.checkSquare : IconType.square}
            onClick={e => {
              e.stopPropagation();
              onToggle(id);
            }}
            className={classNames(styles.checkbox, {
              [styles['checkbox--active']]: isSelected,
            })}
          />
        </td>
      )}
      {columns.map(({ Cell, columnClass, justifyContent, ...column }) => {
        const columnContent = Cell ? (
          <Cell value={get(row, column.accessor)} row={row} column={column} />
        ) : (
          get(row, column.accessor)
        );
        return (
          <td
            key={column.accessor}
            className={classNames(styles.bodyCell, columnClass)}
          >
            <FlexContainer
              alignItems="center"
              justifyContent={justifyContent}
              className={styles.full}
            >
              {columnContent}
            </FlexContainer>
          </td>
        );
      })}
    </tr>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    className: PropTypes.string,
    isHighlighted: PropTypes.bool,
    attributes: PropTypes.shape(),
  }).isRequired,
  id: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      Header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
      Cell: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      columnClass: PropTypes.string,
      tooltip: PropTypes.string,
      justifyContent: PropTypes.string,
      unsortable: PropTypes.bool,
      nameSort: PropTypes.bool,
    }),
  ).isRequired,
  hoverable: PropTypes.bool,
  isSelected: PropTypes.bool,
  onRowClick: PropTypes.func,
  onToggle: PropTypes.func,
};

Row.defaultProps = {
  isSelected: false,
  hoverable: false,
  onToggle: null,
  onRowClick: null,
};
