import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './style.scss';

export const SvgIconType = {
  activities: 'activities',
  ai: 'ai',
  alertTriangle: 'alert-triangle',
  backArrow: 'back-icon',
  camera: 'camera',
  chevronDown: 'chevron-down',
  chevronUp: 'chevron-up',
  clearDay: 'clear-day-icon',
  clearNight: 'clear-night-icon',
  close: 'close-icon',
  cloudLightning: 'cloud-lightning',
  cloudy: 'cloud-icon',
  communication: 'communication',
  connections: 'connections',
  dashboard: 'dashboard',
  designer: 'designer',
  dice: 'dice',
  displays: 'displays',
  fog: 'cloud-icon',
  google: 'google',
  help: 'help',
  lifeloopExpress: 'lifeloop-express',
  lightbulb: 'lightbulb',
  livestream: 'livestream',
  matrixcare: 'matrixcare',
  newActivity: 'new-activity',
  newConnection: 'new-connection',
  notifications: 'notifications',
  partlyCloudyDay: 'partly-cloudy-day-icon',
  partlyCloudyNight: 'partly-cloudy-night-icon',
  pcc: 'pcc',
  phoneIncomingRedCircle: 'phone-incoming-red-circle',
  photoLibrary: 'photo-library',
  playCircle: 'play-circle',
  puzzle: 'puzzle',
  rain: 'rain-icon',
  recipe: 'recipe',
  reports: 'reports',
  residentCommunication: 'resident-communication',
  residents: 'residents',
  ribbon: 'ribbon',
  settings: 'settings',
  sidebarCalendar: 'sidebar-calendar',
  sleet: 'sleet-icon',
  slideshow: 'slideshow',
  snow: 'snow-icon',
  staff: 'staff',
  thermometer: 'thermometer',
  transportation: 'transportation',
  trashBin: 'trash-bin',
  trivia: 'trivia',
  volume: 'volume',
  wheelchair: 'wheelchair',
  wind: 'wind-icon',
  workRequest: 'work-request',
  youtube: 'youtube',
};

export const SvgIconSize = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  grow: 'grow',
};

export default function SVGIcon({
  selectable,
  className,
  svgClassName,
  size,
  type,
  color,
  style,
  ...props
}) {
  const inlineStyle = color ? { ...(style || {}), fill: color } : { ...style };
  return (
    <div
      {...props}
      className={classNames(styles.icon, className, {
        [styles.selectable]: selectable,
      })}
    >
      <svg
        style={inlineStyle}
        className={classNames(
          styles.svg,
          {
            [styles.xsmall]: size === SvgIconSize.xsmall,
            [styles.small]: size === SvgIconSize.small,
            [styles.medium]: size === SvgIconSize.medium,
            [styles.large]: size === SvgIconSize.large,
            [styles.grow]: size === SvgIconSize.grow,
          },
          svgClassName,
        )}
      >
        <use xlinkHref={`#${type}`} />
      </svg>
    </div>
  );
}

SVGIcon.propTypes = {
  className: PropTypes.string,
  svgClassName: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(SvgIconType).map(key => SvgIconType[key]))
    .isRequired,
  size: PropTypes.oneOf(Object.keys(SvgIconSize).map(key => SvgIconSize[key])),
  selectable: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.shape({}),
};

SVGIcon.defaultProps = {
  className: null,
  svgClassName: null,
  color: null,
  size: SvgIconSize.medium,
  selectable: false,
  style: {},
};
