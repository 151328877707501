import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { includes, values } from 'lodash';

import Icon, { IconType } from 'stories/icon';
import FlexContainer from 'web/components/primitives/flex-container';
import LoadingSpinner from 'web/components/primitives/loading.spinner';
import { useSpacer } from 'shared/utils/effects';

import SvgIcon, { SvgIconSize } from 'web/components/primitives/svg.icon';
import styles from './styles.scss';

export const ButtonType = {
  required: 'required',
  primary: 'primary',
  significant: 'significant',
  selected: 'selected',
  additional: 'additional',
  caution: 'caution',
  permanent: 'permanent',
  exception: 'exception',
  outline: 'outline',
  unselected: 'unselected',
  floating: 'floating',
};

export default function Button({
  forwardedRef,
  className,
  innerClassName,
  type,
  loading,
  children,
  disabled,
  symbolIcon,
  svgIcon,
  svgColor,
  actionIcon,
  mini,
  isSubmit,
  onClick,
  fullWidth,
  keyShortcut,
  style,
  ...rest
}) {
  const spacing = useSpacer(rest);
  const iconSize = mini ? 14 : 20;
  let loadingSpinner = null;
  if (loading) {
    loadingSpinner = (
      <LoadingSpinner
        size={iconSize}
        className={styles.spinner}
        light={
          disabled ||
          includes([ButtonType.required, ButtonType.permanent], type)
        }
      />
    );
  }

  return (
    // eslint-disable-next-line
    <button
      {...rest}
      style={{ ...spacing, ...style }}
      onClick={loading ? undefined : onClick}
      ref={forwardedRef}
      disabled={disabled}
      type={isSubmit ? 'submit' : 'button'}
      className={classNames(className, styles.button, styles[type], {
        [styles.mini]: mini,
        [styles.iconOnly]: !children,
        [styles.fullWidth]: fullWidth,
      })}
    >
      {loadingSpinner}
      <FlexContainer
        alignItems="center"
        className={classNames(styles.inner, innerClassName, {
          [styles.loading]: loading,
        })}
      >
        {!!symbolIcon && (
          <Icon
            className={classNames({
              [styles.symbolIcon]: !!children,
            })}
            type={symbolIcon}
            size={iconSize}
          />
        )}
        {!!svgIcon && (
          <SvgIcon
            style={{ marginRight: 8 }}
            type={svgIcon}
            color={svgColor}
            size={SvgIconSize.small}
          />
        )}
        <div className={styles.buttonText}>
          {children}
          <div className={styles.keyShortcut}>{keyShortcut}</div>
        </div>
        {!!actionIcon && (
          <Icon
            className={classNames({
              [styles.actionIcon]: !!children,
            })}
            type={actionIcon}
            size={iconSize}
          />
        )}
      </FlexContainer>
    </button>
  );
}

const iconTypes = PropTypes.oneOf(values(IconType));
Button.propTypes = {
  onClick: PropTypes.func,
  forwardedRef: PropTypes.shape(),
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  type: PropTypes.oneOf(values(ButtonType)),
  symbolIcon: iconTypes,
  actionIcon: iconTypes,
  children: PropTypes.node,
  loading: PropTypes.bool,
  mini: PropTypes.bool,
  isSubmit: PropTypes.bool,
  fullWidth: PropTypes.bool,
  style: PropTypes.shape({}),
};

Button.defaultProps = {
  onClick: undefined,
  forwardedRef: undefined,
  type: ButtonType.additional,
  symbolIcon: undefined,
  actionIcon: undefined,
  children: undefined,
  className: undefined,
  innerClassName: undefined,
  loading: false,
  mini: false,
  isSubmit: false,
  fullWidth: false,
  style: {},
};
