"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportUserType = exports.SUPPORT_USER_TYPES = exports.USER_TYPES = void 0;
var UserType;
(function (UserType) {
    UserType["staff"] = "staff";
    UserType["family"] = "family";
    UserType["resident"] = "resident";
    UserType["enterprise"] = "enterprise";
    UserType["support"] = "support";
})(UserType || (UserType = {}));
var SupportUserType;
(function (SupportUserType) {
    SupportUserType["dev"] = "dev";
    SupportUserType["sales"] = "sales";
})(SupportUserType || (exports.SupportUserType = SupportUserType = {}));
exports.USER_TYPES = {
    staff: {
        value: UserType.staff,
        label: 'Staff',
    },
    family: {
        value: UserType.family,
        label: 'Family',
    },
    resident: {
        value: UserType.resident,
        label: 'Resident',
    },
    enterprise: {
        value: UserType.enterprise,
        label: 'Enterprise',
    },
    support: {
        value: UserType.support,
        label: 'Support',
    },
};
exports.SUPPORT_USER_TYPES = {
    dev: {
        value: SupportUserType.dev,
        label: 'Developer',
    },
    sales: {
        value: SupportUserType.sales,
        label: 'Sales',
    },
};
exports.default = UserType;
